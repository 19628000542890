import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-32">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Other Services</h1>
          <p class="mt-6 mb-6 text-xl text-gray-500 max-w-3xl">MV Property Care provides a wide range of property care services to commercial and residential customers throughout Clarion County, PA, and the surrounding areas, including lawn care, snow removal, and landscaping services. Other property care services that we offer include:</p>
        <ul>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">Mulch installation</h2>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">Leaf removal</h2>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">Spring and Fall cleanups</h2>
          <h2 class="text-xl font-extrabold tracking-tight text-gray-900 mt-6">Firewood: we can deliver and dump cut wood on your site. Please call for details</h2>      

        </ul>
        </div>
      </div>
    </div>

    

  </main>

  <Footer/>
</div>
 
    
  )
}
